<script>
// import { TimelineMax, TweenMax } from 'gsap'
import Icons from '~/mixins/Icons'

export default {
  name: 'BgSwooshHalfRight',
  mixins: [Icons],
  props: {
    devise: { type: Object },
    flip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timeline: null,
      toPath:
                'M110.5,129l14.6-40c0,0,12.8-35.2,12.8-35.1c9.6-26.4,21.5-43.2,32.7-53.8H200v200H62.4 C75.5,190.4,98,164.2,110.5,129z',
    }
  },
  computed: {
    flippy() {
      if (this.flip)
        return 'transform: scaleX(-1)'

      return null
    },
  },
  mounted() {
    // this.timeline = new TimelineMax({ paused: true })
    // const swoosh = this.$refs['swoosh-half-right']

    // this.timeline.to(swoosh, 3, {
    //   transformOrigin: 'center',
    //   morphSVG: this.toPath,
    // })
  },
  methods: {
    onVisibilityChange(entry) {
      if (entry.intersectionRatio * 100 > 0)
        this.timeline.restart()
    },
  },
}
</script>

<template>
  <!-- eslint-disable max-len,no-mixed-spaces-and-tabs -->
  <svg
    v-voix-visibility="{ callback: onVisibilityChange, threshold: [0, 0.25] }"
    xmlns="http://www.w3.org/2000/svg"
    :width="w"
    :height="h"
    viewBox="0 0 200 200"
    role="presentation"
    preserveAspectRatio="none"
    :style="flippy"
  >
    <g>
      <path
        ref="swoosh-half-right"
        :fill="color"
        d="M106,129V89c0,0,0-35.2,0-35.1c0-25.9,0-21.9,0-53.8h94v200h-94C106,183,106,164.7,106,129z"
      />
    </g>
  </svg>
</template>
